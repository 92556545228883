module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://mauricio-carmona-ghost.herokuapp.com","contentApiKey":"0fc5252d733941c9dfea46937a"},"production":{"apiUrl":"https://mauricio-carmona-ghost.herokuapp.com","contentApiKey":"0fc5252d733941c9dfea46937a"}},"siteConfig":{"siteUrl":"https://mauriciocarmona.com","infiniteScroll":true,"postsPerPage":3,"siteTitleMeta":"Blog","siteDescriptionMeta":"Blog personal","shortTitle":"Mauricio Carmona","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":false,"severity":"info"},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":true,"overrideOS":true},
    }]
